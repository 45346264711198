<template>
  <div>
    <NavigationBar titel="Stunden melden" showBackBtn></NavigationBar>
    <section>
      <v-form
        @submit.prevent="createTimeSheet"
        ref="form"
        v-model="helpers.timeSheetCreationDialog.formIsValid"
      >
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <template>
                <v-stepper v-model="helpers.stepperState" vertical>
                  <v-stepper-step :complete="helpers.stepperState > 1" step="1">
                    Meldungsart auswählen
                  </v-stepper-step>

                  <v-stepper-content step="1">
                    <v-card outlined>
                      <v-card-text>
                        <v-item-group
                          v-model="
                            helpers.timeSheetCreationDialog.timeSheetFormType
                          "
                          mandatory
                        >
                          <v-row class="match-height">
                            <v-col
                              v-for="timeSheetFormType in timeSheetFormTypes"
                              :key="timeSheetFormType.id"
                              cols="12"
                              md="6"
                            >
                              <v-item
                                v-slot="{ active, toggle }"
                                :value="timeSheetFormType.id"
                              >
                                <v-card
                                  outlined
                                  @click="toggle"
                                  :style="
                                    active
                                      ? `border: 3px solid #1867c0 !important`
                                      : ''
                                  "
                                >
                                  <v-list-item>
                                    <v-list-item-icon>
                                      <data-avatar
                                        :icon="timeSheetFormType.icon"
                                        :color="timeSheetFormType.color"
                                      ></data-avatar>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                      <v-list-item-title
                                        class="font-weight-medium"
                                      >
                                        {{ timeSheetFormType.title }}
                                      </v-list-item-title>
                                      <v-list-item-subtitle>
                                        {{
                                          timeSheetFormType.description
                                        }}</v-list-item-subtitle
                                      >
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-card>
                              </v-item>
                            </v-col>
                          </v-row>
                        </v-item-group>
                      </v-card-text>
                      <v-divider></v-divider>
                      <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn
                          depressed
                          color="primary"
                          @click="helpers.stepperState = 2"
                        >
                          Weiter
                          <v-icon right>mdi-chevron-right</v-icon>
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-stepper-content>

                  <v-stepper-step :complete="helpers.stepperState > 2" step="2">
                    Daten erfassen
                  </v-stepper-step>

                  <v-stepper-content step="2">
                    <v-card outlined class="">
                      <v-card-text>
                        <v-row>
                          <v-col cols="12">
                            <span v-if="timeSheetTitleTemplates.length"
                              >Vorschläge:</span
                            >
                            <v-chip-group
                              v-if="timeSheetTitleTemplates.length"
                              v-model="timeSheet.title"
                              active-class="primary--text"
                              show-arrows
                            >
                              <v-chip
                                v-for="(item, index) in timeSheetTitleTemplates"
                                :key="index"
                                label
                                :value="item"
                                class="transparent-background font-weight-medium mr-2 mb-3"
                                >{{ item }}
                              </v-chip>
                            </v-chip-group>
                            <v-text-field
                              v-model="timeSheet.title"
                              outlined
                              hide-details="auto"
                              label="Kurze Tätigkeitsbeschreibung"
                              :rules="[rules.required]"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-textarea
                              v-model="timeSheet.description"
                              label="Hinweise/Bemerkungen"
                              hide-details="auto"
                              outlined
                              :rows="3"
                              auto-grow
                            ></v-textarea>
                          </v-col>
                          <!-- <v-col cols="12">
                            <v-autocomplete
                              v-model="timeSheet.user"
                              :items="memberships"
                              outlined
                              hide-details="auto"
                              multiple
                              return-object
                              label="Personen auswählen"
                              item-text="displayName"
                              item-value="uid"
                              :rules="[rules.required]"
                            ></v-autocomplete>
                          </v-col> -->
                        </v-row>
                        <v-row>
                          <v-col cols="auto">
                            <v-btn depressed @click="setNow('start')"
                              ><v-icon left>mdi-clock-in</v-icon>Jetzt
                              einsetzen</v-btn
                            >
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="timeSheet.workedDuration.start.date"
                              type="date"
                              label="Startdatum"
                              hint="DD.MM.YYYY"
                              outlined
                              :rules="[rules.required, rules.startBeforeEnd]"
                              required
                              prepend-inner-icon="mdi-calendar-start"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="3">
                            <v-text-field
                              v-model="timeSheet.workedDuration.start.time"
                              label="Startzeit"
                              hint="HH:MM"
                              :rules="[rules.required, rules.startBeforeEnd]"
                              outlined
                              type="time"
                              prepend-inner-icon="mdi-clock-start"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="auto">
                            <v-btn depressed @click="setNow('end')"
                              ><v-icon left>mdi-clock-in</v-icon>Jetzt
                              einsetzen</v-btn
                            >
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="timeSheet.workedDuration.end.date"
                              type="date"
                              label="Enddatum"
                              hint="DD.MM.YYYY"
                              outlined
                              :rules="[rules.required, rules.startBeforeEnd]"
                              required
                              prepend-inner-icon="mdi-calendar-end"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="3">
                            <v-text-field
                              v-model="timeSheet.workedDuration.end.time"
                              label="Endzeit"
                              hint="HH:MM"
                              outlined
                              :rules="[rules.required, rules.startBeforeEnd]"
                              type="time"
                              prepend-inner-icon="mdi-clock-end"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-divider></v-divider>

                      <v-card-actions>
                        <v-btn
                          depressed
                          :disabled="helpers.timeSheetCreationDialog.loading"
                          @click="helpers.stepperState = 1"
                        >
                          <v-icon left>mdi-chevron-left</v-icon>Zurück
                        </v-btn>
                        <v-spacer></v-spacer>

                        <v-btn
                          depressed
                          color="primary"
                          :disabled="
                            !helpers.timeSheetCreationDialog.formIsValid
                          "
                          @click="helpers.stepperState = 3"
                        >
                          Weiter
                          <v-icon right>mdi-chevron-right</v-icon>
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-stepper-content>

                  <v-stepper-step :complete="helpers.stepperState > 3" step="3">
                    Daten prüfen
                  </v-stepper-step>

                  <v-stepper-content step="3">
                    <v-card flat>
                      <v-card-text>
                        <v-row class="match-height">
                          {{ timeSheet }}
                        </v-row>
                        <v-row class="mt-8">
                          <v-col cols="12">
                            <v-alert text color="primary" type="info">
                              <div class="title">Hinweis</div>
                              <div class="mt-2">
                                Erstellte Stundenmeldungen werden
                              </div>
                            </v-alert>
                            <v-alert
                              v-if="
                                this.helpers.timeSheetCreationDialog
                                  .errorMessage
                              "
                              text
                              color="error"
                              type="error"
                            >
                              <div class="title">Fehler</div>
                              <div class="mt-2">
                                {{
                                  this.helpers.timeSheetCreationDialog
                                    .errorMessage
                                }}
                              </div>
                            </v-alert>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          depressed
                          @click="helpers.stepperState = 2"
                          :disabled="helpers.timeSheetCreationDialog.loading"
                        >
                          <v-icon left>mdi-chevron-left</v-icon>Zurück
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                          depressed
                          color="primary"
                          :loading="helpers.timeSheetCreationDialog.loading"
                          @click="createTimeSheet()"
                        >
                          <v-icon left>mdi-ray-start-arrow</v-icon>
                          Speichern
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-stepper-content>
                </v-stepper>
              </template>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </section>
  </div>
</template>

<script>
import { auth, functions, Timestamp } from "@/firebase";
import { ADMIN } from "@/store/modules.js";
import { GET_USER_LIST } from "@/store/action-types.js";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import DataAvatar from "@/components/_systemwide/DataAvatar.vue";

export default {
  name: "accounting-time-sheets-time-sheet-new",
  components: {
    NavigationBar,
    DataAvatar,
  },
  data() {
    return {
      timeSheetFormTypes: [
        {
          id: "dutyReport-related",
          title: "Einsatzdienst",
          description: "Zusätzliche Einsatzmittel- und Funktionserfassung",
          icon: "fire-truck",
          color: "#F44336FF",
        },
        {
          id: "course-related",
          title: "Lehrgang/Fortbildung",
          description: "Zusätzliche Nachweiserfassung",
          icon: "school",
          color: "#009688FF",
        },
        {
          id: "other",
          title: "Sonstige Stunden",
          description: "Allgemeine Erfassung",
          icon: "dots-horizontal-circle",
          color: "#607D8BFF",
        },
      ],
      timeSheet: {
        type: "", // "dutyReport-related", "courses-related", "dutyPosition-related", "other"
        title: "",
        description: "",
        workedDuration: {
          start: { date: null, time: null },
          end: { date: null, time: null },
        },
        user: {
          uid: auth.currentUser.uid,
          membershipId: auth.currentUser.uid,
          displayName: auth.currentUser.displayName,
        },
        payrollType: {
          id: null,
          title: null,
        },
      },
      helpers: {
        stepperState: 1,
        timeSheetCreationDialog: {
          formIsValid: false,
          model: false,
          loading: false,
          errorMessage: "",
          timeSheetFormType: "",
        },
      },
      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
        startBeforeEnd: () =>
          (!this.fieldsAreFilled &&
            this.convertToTimestamp(
              this.timeSheet.workedDuration.start.date,
              this.timeSheet.workedDuration.start.time
            ).valueOf() <
              this.convertToTimestamp(
                this.timeSheet.workedDuration.end.date,
                this.timeSheet.workedDuration.end.time
              ).valueOf()) ||
          "Start muss vor Ende liegen",
      },
    };
  },
  computed: {
    timeSheetTitleTemplates() {
      return this.$store.state.organization.activeOrganization.config.accounting
        .timeSheets.titleTemplates;
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      await this.$store.dispatch(`${ADMIN}${GET_USER_LIST}`, {
        organizationId: this.$route.params.organizationId,
        queryIsFiltered: true,
        query: {
          type: "active",
        },
      });
    },
    validate() {
      this.$refs.form.validate();
    },
    async createTimeSheet() {
      this.validate();
      if (!this.helpers.timeSheetCreationDialog.formIsValid) {
        alert("Prüfen Deine Eingabe und versuchen es erneut.");
        return;
      }

      this.helpers.timeSheetCreationDialog.loading = true;
      this.helpers.timeSheetCreationDialog.errorMessage = "";

      try {
        const timeSheetData = {
          ...this.timeSheet,
          workedDuration: {
            start: {
              timestamp: this.convertToTimestamp(
                this.timeSheet.workedDuration.start.date,
                this.timeSheet.workedDuration.start.time
              ),
            },
            end: {
              timestamp: this.convertToTimestamp(
                this.timeSheet.workedDuration.end.date,
                this.timeSheet.workedDuration.end.time
              ),
            },
          },
        };

        console.log(timeSheetData);
        const callFunction = functions.httpsCallable(
          "accounting-createTimeSheet"
        );
        const response = await callFunction({
          organizationId: this.$route.params.organizationId,
          timeSheet: timeSheetData,
        });

        const timeSheetId = response.data;
        this.$router.push({
          name: "accounting-time-sheets-details",
          params: {
            organizationId: this.$route.params.organizationId,
            itemId: timeSheetId,
          },
        });
      } catch (error) {
        this.helpers.timeSheetCreationDialog.errorMessage =
          error.message ||
          "Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es erneut.";
        console.error("Error creating report: ", error);
      } finally {
        this.helpers.timeSheetCreationDialog.loading = false;
      }
    },
    setNow(type) {
      const now = new Date();
      const date = now.toISOString().split("T")[0];
      const time = now
        .toTimeString()
        .split(" ")[0]
        .split(":")
        .slice(0, 2)
        .join(":");
      if (type === "start") {
        this.timeSheet.workedDuration.start.date = date;
        this.timeSheet.workedDuration.start.time = time;
      } else if (type === "end") {
        this.timeSheet.workedDuration.end.date = date;
        this.timeSheet.workedDuration.end.time = time;
      }
    },
    convertToTimestamp(date, time) {
      const input = date + " " + time;
      return Timestamp.fromDate(new Date(input.replace(/-/g, "/")));
    },
  },
};
</script>

<style></style>
